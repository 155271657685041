import React, {useEffect, useRef} from 'react';
import cn from 'classnames';
import Icon from "../Icon";
import {useChatStore} from "../../store/chat-store";
import useLongPress from "../../utils/hooks/useLongPress";
import {Image} from 'antd';
import {extractText} from "../../utils/chat-utils";
import {extractTime} from "../../utils/date";


const MessageItem = ({message}) => {

  const chat = useChatStore((state) => state.chat);
  const onLongPress = useLongPress();

  const updateSelectedMessageData = useChatStore((state) => state.updateSelectedMessageData)
  const updateChatModalOpen = useChatStore((state) => state.updateChatModalOpen)

  const messageActionsModal = (active, messageId) => {
    if (active) {
      updateChatModalOpen(active);

      if (messageId) {
        const messageData = chat.filter(msg => msg.message_id === message.message_id)[0];

        updateSelectedMessageData({
          answer: {
            user: {
              name: messageData?.senderName,
            },
            date: messageData?.date.getTime() / 1000,
            message: extractText(messageData?.text)?.text
          }
        })
      }

    } else {
      updateChatModalOpen(false)
      updateSelectedMessageData(null)
    }
  }
  const ref = useRef(null)

  useEffect(() => {
    ref.current.addEventListener('contextmenu', function (ev) {
      ev.preventDefault();
      messageActionsModal(true, message.message_id)
      return false;
    }, false);
  }, [ref])


  return (
    <div
      className={cn('message', {'message_outgoing': message.senderStatus === 'user'}, {'message_incoming': message.senderStatus === 'clinic'})}
      data-name={message.senderName}
      ref={ref}
      data-id={message.message_id}
    >
      <div
        className="message__inner"
        {...onLongPress(() => messageActionsModal(!message?.files?.length && true, message.message_id))}
      >
        {message?.file && (
          <span>
            <div className="message__file-box">
              {message?.file?.type === 'image' && (
                <Image
                  preview={{
                    mask: false,
                    toolbarRender: () => false,
                    destroyOnClose: true,
                    movable: false
                  }}
                  className="message__file-image"
                  src={message.file.urlShow}
                  alt={message.file.name}
                />
              )}
              {message?.file.type !== 'image' && (
                <span>
                  Ссылка для скачивания: &nbsp;
                  <a href={message?.file.urlDownload} className='message__file-doc'
                     download={message?.file.name}>{message?.file.name}</a>
                </span>
              )}
            </div>
          </span>
        )}
        <div className="message__text">
          {extractText(message.text)?.answer && (
            <div className="message__quote">
              <div className="message__quote-text">
              <span>
                {extractText(message.text)?.answer?.name}
              </span>
                <p>{extractText(message.text)?.answer?.text}</p>
              </div>
            </div>
          )}
          <p>{extractText(message.text)?.text}</p>
        </div>
        <div className="message__info">
          <div className="message__time">{extractTime(message.date)}</div>
          <div className="message__status">
            <Icon name="tick-12"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageItem;
