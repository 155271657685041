import React, {useEffect, useRef, useState} from 'react';
import MessageItem from "./message-item";
import {useChatStore} from "../../store/chat-store";
import {prettyDate} from "../../utils/date";
import {ChatDate} from "./chat-date";

const MessageList = () => {
    window.__localeId__ = "ru";

    const chat = useChatStore((state) => state.chat);
    const updateChat = useChatStore((state) => state.updateChat);

    const chatEndRef = useRef(null);
    const [fixedDate, setFixedDate] = useState();

    let tmpDate = null;

    const scrollToBottom = () => {
      if (chat.length !== 0 && chatEndRef.current) {
        setTimeout(() => {
          chatEndRef.current.scrollIntoView({behavior: "smooth"});
        }, 100);
      }
    };

    useEffect(() => {
      if (chat.length <= 15) {
        scrollToBottom();
      }
    }, [chat]);

  const [scroll, setScroll] = useState(0);
  const [prevScroll, setPrevScroll] = useState(0);
  const [hasFetched, setHasFetched] = useState(false);
  const scrollRef = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scroll < 200 && scroll < prevScroll && !hasFetched) {
      scrollRef.current = window.scrollY + 200;

      updateChat(chat[0]?.message_id)
        .then(() => {
          window.scrollTo(0, scrollRef.current);
          setHasFetched(true);
        });
    }

    if (scroll >= prevScroll) {
      setHasFetched(false);
    }

    setPrevScroll(scroll);
  }, [scroll]);


    return (
      <div className="main chat">
        <div className="chat__flex container container_12">
          {fixedDate && <div className="chat__date-fixed">{prettyDate(fixedDate)}</div>}
          {chat.length ? chat.map((message, index) => {
            const separatorDate = (tmpDate === prettyDate(message?.date) ? null : prettyDate(message?.date))
            if (!tmpDate || tmpDate !== prettyDate(message?.date)) tmpDate = prettyDate(message?.date);
            return (
              <>
                {separatorDate && (<ChatDate date={separatorDate}/>)}
                <MessageItem key={message.id} message={message}/>
              </>
            )
          }) : null}
          <span ref={chatEndRef} className="chat__end"></span>
        </div>
      </div>
    );
  }
;

export default MessageList;
