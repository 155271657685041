import {create} from 'zustand';
import apiClient from "../api/axiosConfig";
import {apiChatGetHistory, apiChatShowMore} from "../api/chatApi";

export const useChatStore = create((set, get) => ({
  chat: [],
  selectedMessageData: null,
  replyMessageId: null,
  chatModalOpen: false,
  fileUploadingStatus: false,

  updateChat: async (messageId) => {
    const messages = [];
    const users = {};

    const data = messageId ? await apiChatShowMore(messageId) : await apiChatGetHistory();

    if (data?.status === "success") {
      const chatMessages = data?.data.messages.reverse();
      const chatUser = data?.data.users;
      const chatFiles = data?.data.files;

      chatUser.map(user => {
        users[user.id] = user;
      });

      chatMessages.map(message => {
        messages.push({
          "user_id": message.author_id,
          "chat_id": message.chat_id,
          "message_id": message.id,
          "senderStatus": users[message.author_id]?.connector ? 'user' : 'clinic',
          "senderName": users[message.author_id]?.name,
          "text": message.text,
          "date": new Date(message.date),
          "file": message.params.FILE_ID ? chatFiles.filter(file => file.id === Number(message.params.FILE_ID))[0] : null
        });
      });
    }

    // Получаем текущее состояние чата
    const currentChat = get().chat;

    if (messageId) {
      // Если передан messageId, добавляем новые сообщения в конец существующего массива чата
      set({chat: [...messages, ...currentChat]});
    } else {
      // Если messageId не передан, заменяем текущие сообщения новыми
      set({chat: messages});
    }
  },

  addChatMessage: (data) => set((state) => ({chat: [...state.chat, data]})),
  updateSelectedMessageData: (data) => set(() => ({selectedMessageData: data})),
  updateReplyMessageId: (data) => set(() => ({replyMessageId: data})),
  updateChatModalOpen: (data) => set(() => ({chatModalOpen: data})),
  updateFileUploadingStatus: (data) => set(() => ({fileUploadingStatus: data})),
}));
