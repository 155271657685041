import React, {useEffect, useRef, useState} from 'react';
import Icon from "../Icon";
import {useChatStore} from "../../store/chat-store";
import {useUserStore} from "../../store/user-store";
import {apiChatSendMessage} from "../../api/chatApi";
import {LoadingOutlined} from '@ant-design/icons';
import {extractText} from "../../utils/chat-utils";

const ChatFooter = () => {

  const {firstName, lastName, email} = useUserStore((state) => [state.firstName, state.lastName, state.email]);

  const chat = useChatStore((state) => state.chat);
  const addChatMessage = useChatStore((state) => state.addChatMessage);

  const selectedMessageData = useChatStore((state) => state.selectedMessageData);
  const updateSelectedMessageData = useChatStore((state) => state.updateSelectedMessageData)


  const fileUploadingStatus = useChatStore((state) => state.fileUploadingStatus);
  const updateFileUploadingStatus = useChatStore((state) => state.updateFileUploadingStatus);

  const [messageText, setMessageText] = useState('');

  const textChange = (e) => {
    setMessageText(e.target.value)
  }

  const textField = useRef();
  const fileField = useRef();

  const sendMessage = () => {
    let msgText = messageText.trim()
    if (msgText.trim() || fileField.current.value.length) {
      let messageSendTime = +new Date() / 1000;

      if (fileField.current.files.length && fileField.current.files[0]) {
        const maxAllowedSize = 25 * 1024 * 1024;
        if (fileField.current.files[0].size > maxAllowedSize) {
          alert('Размер файла не должен превышать 25мб')
          fileField.current.value = ''
          if (!msgText.length) return
        }
      }

      // addChatMessage({
      //   id: chat.length + 1,
      //   senderStatus: 'user',
      //   senderName: `${firstName} ${lastName}`,
      //   email: email,
      //   text: msgText,
      //   // answerMessageId: selectedMessageData ? replyMessageId : null,
      //   date: messageSendTime,
      // })

      setMessageText('');
      selectedMessageData && updateSelectedMessageData(null);
      textField.current.focus();

      updateFileUploadingStatus(true);

      apiChatSendMessage(messageText, fileField.current, selectedMessageData).then((res) => {
        updateFileUploadingStatus(false);
        if (fileField.current && fileField.current.value !== null) {
          fileField.current.value = ''
        }
        if (selectedMessageData) {
          updateSelectedMessageData(null)
        }
      })
    }
  }

  const cancelReplayMessage = () => {
    selectedMessageData && updateSelectedMessageData(null);
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  }

  return (
    <div className="chat-actions">
      <div className="container container_12">
        {selectedMessageData && (
          <div className="chat-actions-quote">
            <div className="message__quote">
              <div className="message__quote-name">{selectedMessageData?.answer?.user?.name}</div>
              <div className="message__quote-text">{extractText(selectedMessageData?.answer.message)?.text}</div>
            </div>
            <span onClick={cancelReplayMessage} className="chat-actions-quote__cancel">
              <Icon name="close"/>
            </span>
          </div>
        )}
        <div className="chat-actions__inner">
          <form className="chat-attachment-form">
            <label className="chat-attachment">
              {fileUploadingStatus ? <div className="message__loader"><LoadingOutlined/></div> : (
                <>
                  <Icon name="attachment"/>
                  <input
                    className="chat-attachment__input"
                    type="file"
                    ref={fileField}
                    onChange={sendMessage}
                    accept="image/*,video/*,.pdf,.doc,.pdf,docx, .xls,.xlsx,.pptx,.ppt,.pptx,.txt,.rtf"
                    maxLength={111}
                  />
                </>
              )}
            </label>
          </form>
          <div className="chat-input-text">
            <textarea ref={textField} className="chat-input-text__textarea" onKeyDown={handleKeyDown}
                      onChange={textChange} value={messageText}
                      placeholder="Сообщение..."></textarea>
          </div>
          <button onClick={sendMessage} className="chat-send-message">
            <Icon name="send"/>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatFooter;
