import React, {useEffect, useLayoutEffect} from 'react';
import {BrowserRouter as Router, Routes, Route, useLocation} from 'react-router-dom';
import {use100vh} from 'react-div-100vh'
import LayoutBase from "@components/layouts/LayoutBase";
import AuthDispatcher from "@pages/AuthDispatcher";
import PageLogin from "@pages/PageLogin";
import PageNotifications from "@pages/PageNotifications";
import PageCall from "@pages/PageCall";
import PageChat from "@pages/PageChat";
import PageMain from "@pages/PageMain";
import PageMainAuth from "@pages/PageMainAuth";
import PageRegister from "@pages/PageRegister";
import PageSettings from "@pages/PageSettings";
import RequiredAuth from "./components/RequiredAuth";
import {SIPProvider} from "react-sipjs";
import OfflineMessage from "./components/OfflineMessage";
import {useUserStore} from "./store/user-store";
import PageLoginNewPassword from "./pages/PageLoginNewPassword";
import PageLoginAuth from "./pages/PageLoginAuth";
import {deviceType} from "./utils/getDeviceType";
import Test from "./pages/Test";



function App() {
  //const height = use100vh()
  const [getPersonalInfo, logout] = useUserStore((state) => [state.getPersonalInfo, state.logout]);
  const [firstName] = useUserStore((state) => [state.firstName]);



  useLayoutEffect(() => {
    //getPersonalInfo().catch(err => logout())
    deviceType()
    getPersonalInfo()
    localStorage.removeItem('animationShown')
  }, []);

  useEffect(() => {
    //document.body.style.cssText = `--vh: ${height}px`
    if ('visualViewport' in window) {
      requestAnimationFrame(vhFix);

      function vhFix() {
        const vh = window.visualViewport.height;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        requestAnimationFrame(vhFix);
      }
    } else {
      document.body.style.minHeight = '100vh'
    }
  }, [])

  return (
    <>
      <LayoutBase>
        <Router>
          <Routes>
            <Route index element={<AuthDispatcher/>}/>
            <Route path='/test' element={<Test/>}/>
            <Route path='/main' element={<PageMain/>}/>
            <Route path='/register' element={<PageRegister/>}/>
            <Route path='/login' element={<PageLogin/>}/>
            <Route path='/login-auth' element={<PageLoginAuth/>}/>
            <Route path='/login-new-password' element={<PageLoginNewPassword/>}/>
            <Route element={<RequiredAuth/>}>
              <Route path='/main-auth' element={firstName ? <PageMainAuth/> : <PageSettings/>}/>
              <Route path='/settings' element={<PageSettings/>}/>
              <Route path='/notifications' element={<PageNotifications/>}/>
              <Route path='/call' element={
                <SIPProvider
                  options={{
                    domain: "188.120.230.120.sslip.io",
                    webSocketServer: "wss://188.120.230.120.sslip.io:8089/ws",
                  }}
                >
                  <PageCall/>
                </SIPProvider>
              }/>
              <Route path='/chat' element={<PageChat/>}/>
            </Route>
          </Routes>
        </Router>
        <OfflineMessage/>
      </LayoutBase>

    </>
  );
}

export default App;
