import {motion} from "framer-motion";
import Icon from "../Icon";
import {useChatStore} from "../../store/chat-store";

const ModalChatMessage = () => {

  const chatModalOpen = useChatStore((state) => state.chatModalOpen);
  const selectedMessageData = useChatStore((state) => state.selectedMessageData);

  const updateChatModalOpen = useChatStore((state) => state.updateChatModalOpen)
  const updateSelectedMessageData = useChatStore((state) => state.updateSelectedMessageData)

  const show = {
    opacity: 1,
    display: "block"
  };

  const hide = {
    opacity: 0,
    transitionEnd: {
      display: "none"
    }
  };

  const show2 = {
    translateY: 0,
  };

  const hide2 = {
    translateY: '100%',
  };

  const replyMessage = () => {
    if (selectedMessageData) {
      updateSelectedMessageData(selectedMessageData);
    }
    updateChatModalOpen(false);
  }

  const copyMessage = async () => {
    updateChatModalOpen(false);
    await navigator.clipboard.writeText(selectedMessageData?.answer.message);
    updateSelectedMessageData(null);
  }

  const closeModal = () => {
    updateChatModalOpen(false);
    updateSelectedMessageData(null);
  }


  return (
    <motion.div
      animate={chatModalOpen ? show : hide}
      className={`modal modal_type-3 modal-chat-message ${chatModalOpen ? 'modal--show' : ''}`}
      data-popup="chatMessageActions"
    >
      <div className="modal__inner">
        <div className="modal__wrap">
          <div onClick={closeModal} className="modal__close modal-close">
            <Icon name="close"/>
          </div>
          <div className="modal__content">
            <div className="modal-message-actions">
              <button onClick={replyMessage} className="modal-message-action reply-message">
                <Icon name="reply"/>
                <span>Ответить</span>
              </button>
              <button onClick={copyMessage} className="modal-message-action copy-message">
                <Icon name="copy"/>
                <span>Скопировать</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}
export default ModalChatMessage
